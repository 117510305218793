
import "../sidebar/sidebar.css";
import React from "react";
import { Component } from "react";

export const Sidebar = ({ width, height, children}) => {
    const [xPosition, setX] = React.useState(width);
   
  
    const toggleMenu = () => {
      if (xPosition > 0) {
        setX(0);
      } else {
        setX(width);
      }
    };

    React.useEffect(() => {
      setX(width);
    }, []);
    return (
      <React.Fragment>
        <div
          className="side-bar"
          style={{
            transform: `translatex(${xPosition}px)`,
            width: width,
            minHeight: height
          }}
        >
          <button
            onClick={() => toggleMenu()}
            className="toggle-menu"
            id="docker-btn"
            style={{
              transform: `translate(-20px, 20vh)`
            }}
          ><i className="icon-shopping-bag icon-sidebar"></i></button>
          <div className="content" style={{overflowY:"scroll"}}>{children}</div>
        </div>
      </React.Fragment>
    );
  };