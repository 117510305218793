import React, {Component} from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import Slider from 'react-slick';
import environment from '../../environment';
import CarouselItem from './carousel-product';
import swal from 'sweetalert';
class RecentProducts extends Component
{
    state = {
        products: []
    }

    componentDidMount()
    {
        this.getRecentProducts();
    }

    getRecentProducts = () =>
    {
        axios.get(environment.getTopProducts)
        .then(response=>
            {
                if(response.data.resultado)
                {
                    this.setState({products: response.data.datos});
                    this.sliders();
                }
            })
            .catch(error=>
                {
                  swal("Error", "Ocurrió un error en la petición: " + error, "error");
                })
    }

    sliders()   
    {
        
    }                

    render()
    {
        var settings = {
            swipeToSlide:true,
            infinite: true,
            speed:2000,
            autoplay:true,
            autoplaySpeed:1500,
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  dots:true
                }
              }
            ]
          };
        return(
            <div className="site-section">
            <div className="container">
              <div className="row">
                <div className="title-section mb-5 col-12">
                  <h2 className="text-uppercase">Ultimos productos</h2>
                </div>
              </div>
              <div>                
                <Slider {...settings}>
                {
                    this.state.products.map(product=>
                        {
                            return(
                                <div key={product.urlImagenes}>
                                    <CarouselItem {...product} />
                                </div>
                            )
                        })
                }
                </Slider>
              </div>
            </div>
          </div>
        );
    }
}

export default RecentProducts;