import React, {Component} from 'react';
import axios from 'axios'
import environment from '../../environment'
import mobileMenuWrapper from '../../libraries/mobileStyle'
import sessionCart from '../../util/cartStorage'
import {Sidebar} from '../sidebar/sidebar'
import swal from 'sweetalert';
import isEmpty from '../../util/isEmpty';
import swalQuestion from '../../util/swalQuestion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import  ReactSwipeEvents  from 'react-swipe-events';
class header extends Component {
  state = {
    categorias: [],
    categoriasExtra: [],
    aplicanCategoriasExtra: false,
    isLoading:true,
    cartItems:0,
    cartElements:[],
    showSidebar:false, 
    mayoreo:0,
    assignedSession: "", 
    updatingCart:false,
    isLoggedIn:false,
    searchTerm:""
  }

  componentDidMount()
  {
    this.getCategories();
    let cookieSession =  document.cookie.split('; ').find(identifier=> identifier.startsWith('session'))
    if(isEmpty(cookieSession))
    {
      sessionCart();
    }
    this.getCartProducts();
  }

  getStoreByCategory = (e, cat) =>
  {
      e.preventDefault();
      this.props.setCategoryFilter(cat);
  }

  goToCheckOut = () =>
  {
    this.sbSwapped(null);
    var vista = "checkout"
    this.props.setCurrentInstance(vista);
  }

  removeQuantity = (e) => 
  {
    if(this.state.updatingCart)
    {
      swal("Actualizando...", "No se puede remover hasta que termine el proceso de actualización", "info");
      return;
    }
    let currentQuantity = e.cantidad;
    if(currentQuantity == 1)
    {
      swal("Minimo alcanzado", "No puede tenerse una cantidad menor a uno, si deseas eliminarlo usa el botón para eliminar del carrito", "error");
      return;
    }
    else
    {
      this.setState({updatingCart:true}, ()=>
      {
        var req = {
          idCartContent: e.idCartContent, 
          idCart: e.idCart,
          idProducto: e.idProducto, 
          quantity: parseInt(currentQuantity - 1),
          dateAdded: new Date()
        }
        axios.put(environment.editItem + "/" + e.idCartContent, req).then(response=>
          {
            if(response.data.resultado)
            {
              swal("Éxito", "Articulo actualizado", "success")
              this.getCartProducts();
              this.setState({updatingCart:false});
            }
          }).catch(error=>
            {
              swal("Error", "Ocurrió un error con la petición: " + error, "error");
            })
      })
      
    }
  }

  addQuantity = (e) => 
  {
    console.log(e);
    let cantidadFutura = parseInt(e.cantidad) + 1;
    if(cantidadFutura > e.stock)
    {
      swal("Stock insuficiente",
          "El stock disponible para este producto es de " + e.stock + ", por lo que no es posible agregar más",
          "info")
      return;
    }

    if(this.state.updatingCart)
    {
      swal("Actualizando...", "No se puede agregar hasta que termine el proceso de actualización", "info");
      return;
    }
    this.setState({updatingCart:true}, ()=>
    {
      var req = 
      {
          idCartContent: e.idCartContent,
          idCart: e.idCart,
          idProducto: e.idProducto,
          quantity:1,
          dateAdded: new Date()
      }
      axios.post(environment.addItem, req).then(response=>
        {
          if(response.data.resultado)
          {
            swal("Éxito", "Articulo actualizado", "success")
            this.getCartProducts();
            this.setState({updatingCart:false});
          }
        }).catch(error=>
          {
            swal("Error", "Ocurrió un error en la petición: " + error, "error");
          })
    })
    
  }
  deleteFromCart = (e) => 
  {
    var question = swalQuestion("¿Estás seguro que deseas eliminar el producto del carrito?");
    swal({...question}).then(value=>
      {
        if(value)
        {
          this.setState({updatingCart:true}, ()=>
          {
            axios.delete(environment.deleteItem + "/" + e.idCartContent).then(response=>
              {
                let result = response.data;
                if(result.respuesta.success)
                {
                  swal("Éxito", "Se eliminó el producto del carrito", "success")
                  this.getCartProducts();
                  this.setState({updatingCart:false});
                }
                else
                {
                  swal("Error", "Ocurrió un error con la acción: " + result.respuesta.userMessage, "error");
                }
              })
              .catch(error=>
                {
                  swal("Error", "Ocurrió un error con la petición: " + error, "error");
                })
          });
       
        }
      })
  }

  getIfUserIsLogged = (e) =>
  {
    e.preventDefault();
    let cookieSession =  document.cookie.split('; ').find(identifier=> identifier.startsWith('session'))
    let sessionStr= cookieSession.split("=")[1];
    let sessionObj = JSON.parse(sessionStr);
    if(!isEmpty(sessionObj.jwt))
    {
      this.setState({isLoggedIn:true});
    }
  }
  getCartProducts = () =>
  {
    var cartGenerated = [];
    cartGenerated.push(<h2 key={0} className="cart-header">Carrito</h2>)
    cartGenerated.push(<hr key={"x"}></hr>)
    let noItemsInCart = <h5 key={1}>No tienes elementos en el carrito, agrega unos cuantos</h5>; 
    let cookieData = document.cookie.split('; ').find(identifier=> identifier.startsWith('session'))
    if(!isEmpty(cookieData)){
        this.setState({assignedSession:cookieData}, ()=>
        {
            let sessionStr= this.state.assignedSession.split("=")[1];
            let sessionObj = JSON.parse(sessionStr);
            let req = 
            {
              sessionId: sessionObj.sessionId,
              loggedSession: 0
            }
            if(!isEmpty(sessionObj.jwt))
            {
              this.setState({isLoggedIn:true});
            }
            axios.post(environment.getCart, req)
            .then(response=>
              {
                if(!response.data.resultado)
                {
                  swal("Error al solicitar carrito", 
                  "Ocurrió un error al solicitar el carrito, favor de revisar con el administrador", 
                  "error");
                }
                else
                {
                  let items = response.data.datos;
                  let cartElements = 0
                  items.forEach(element => {
                    cartElements += parseInt(element.cantidad)
                  });
                  this.setState({cartItems:cartElements})
                  if(cartElements > 0)
                  {
                    let sumItems = 0;
                    if(cartElements >= items[0].minimoMayoreo)
                    {
                      this.setState({mayoreo:1}, ()=>
                      {
                        items.forEach(element => {
                          let qty = parseInt(element.cantidad);
                          let price = this.state.mayoreo != 0 ? parseInt(Math.ceil(element.precioMayoreo)) : parseInt(element.precio_Venta);
                          let result = qty * price;
                          sumItems += result;
                        });
                      })
                    }
                    else
                    {
                      this.setState({mayoreo:0}, ()=>
                      {
                        items.forEach(element => {
                          let qty = parseInt(element.cantidad);
                          let price = this.state.mayoreo == 1 ? parseInt(element.precioMayoreo) : parseInt(element.precio_Venta);
                          let result = qty * price;
                          sumItems += result;
                        });
                      })
                    }
                    
                    items.map(element => {
                      let price;
                      if(this.state.mayoreo == 1)
                      {
                        price = <div><s>${element.precio_Venta}.00</s><b> ${Math.ceil(element.precioMayoreo)}.00</b></div>
                      }
                      else{
                        price = <b>${element.precio_Venta}.00</b>
                      }
                      var control = <div key={element.idCartContent} className="container-fluid">
                        <div className="row">
                          <div>
                            <img src={element.imagen.split("|")[0]} className="img-thumbnail eve-cart-item-img" />
                          </div>
                          <div>
                          {element.nombre.substring(0, 20)} <br />
                          {element.cantidad} x <a>{price}</a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 eve-cart-div">
                          <button onClick={()=> this.removeQuantity(element)} className="btn btn-warning eve-btn-cart eve-bg"><FontAwesomeIcon icon={faMinus} /></button>
                          <button onClick={() => this.addQuantity(element)} className="btn btn-success eve-btn-cart eve-bg"><FontAwesomeIcon icon={faPlus} /></button>
                          <button onClick={() => this.deleteFromCart(element)} className="btn btn-danger eve-btn-cart eve-bg"><FontAwesomeIcon icon={faTrash} /></button>
                          </div>
                        </div>
                      </div>
                      cartGenerated.push(control);
                    });
                    var footer = 
                    <div key={"total"}>
                        <hr></hr>
                        <div><p className="eve-footer-cart-p">Total: <b>${sumItems}.00</b></p></div>
                        <div className="container text-center">
                          <button onClick={() => this.goToCheckOut()} className="btn eve-footer-cart-btn">Completar pedido</button>
                        </div>
                    </div>
                    cartGenerated.push(footer);
                    this.setState({cartElements:cartGenerated});
                  }
                  else
                  {
                    cartGenerated.push(noItemsInCart)
                    this.setState({cartElements:cartGenerated})
                  }
                }
              })
            .catch(error =>
              {
                swal("Error de conectividad", "Excepción: " + error, "error");
              })
        })
    }
    else
    {
      cartGenerated.push(noItemsInCart);
      this.setState({cartElements:cartGenerated});
    }
    
  }
  
  endCurrentSession = (e) =>
  {
    e.preventDefault();
    let cookieData = document.cookie.split('; ').find(identifier=> identifier.startsWith('session'))
    let jsonCookie = JSON.parse(cookieData.split('=')[1]);
    let newCookie = 
    {
      sessionId: jsonCookie.sessionId,
      cartId: jsonCookie.cartId
    }
    let keyDate = new Date();
    let expiration = new Date(keyDate.getTime() + 30*60000).toUTCString();
    let cookieToAdd = "session=" + JSON.stringify(newCookie) + "; expires=" + expiration +"; path=/";
    document.cookie = cookieToAdd;
    window.location.href = "index.html";
  }

  goToProductDetails = (e) => 
  {
    e.persist();
    var product = e.target.innerText;
    this.props.setProductUniqueView(product);
  }

  returnHome = (e) =>
  {
    e.preventDefault();
    this.props.setCurrentInstance('home')
  }
  
  searchTerm = (e) =>
  {
    e.persist();
    if(e.charCode == 13)
    {
      if(!isEmpty(this.state.searchTerm))
      {
        this.props.searchQuery(this.state.searchTerm)
      }
      else
      {
        return;
      }
    }
  }

  setSearchQuery = (e) =>
  {
    e.persist();
    this.setState({searchTerm: e.target.value})
  }
  sbSwapped = (e) =>
  {
    var sidebarBtn = document.getElementById('docker-btn');
    sidebarBtn.click();
  }
  toggleSidebar = (e) => 
  {
    e.preventDefault();
    var sidebarBtn = document.getElementById('docker-btn');
    sidebarBtn.click();
  }
  updateCart = (e) => 
  {
    e.preventDefault();
    var sidebarBtn = document.getElementById('docker-btn');
    sidebarBtn.click();
    this.setState({cartElements:[]})
    this.getCartProducts();
    sidebarBtn.click();
  }
  
  getCategories = () => {
  axios.get(environment.getCategories)
    .then(response =>
      {
        let listadoCompleto = response.data.datos;
        this.setState({categorias: listadoCompleto}, ()=>
        {
          mobileMenuWrapper();
        })
        
      })
      .catch(error =>
        {
          swal("Error", "Error de conectividad: " + error, "error");
        })
      }
      
  render()
  {
   const imageUrl = "logo"
   const activeSession = this.state.isLoggedIn
   let endSessionBtn;
   if(activeSession)
   {
     endSessionBtn = <a href="#" className="icons-btn d-inline-block" onClick={(e) => this.endCurrentSession(e)}>Cerrar sesión</a>
   }
   else
   {
     endSessionBtn = "";
   }

  return(
    <div>
    <div className="site-navbar bg-white py-2" id="topnavbar">
        <div className="search-wrap">
          <div className="container">
            <a href="#" className="search-close js-search-close"><span className="icon-close2"></span></a>
            
            <input type="text" className="form-control" onKeyPress={(e) => this.searchTerm(e)} 
            onChange={(e) => this.setSearchQuery(e)} placeholder="Qué estás buscando..."
            value={this.state.searchTerm} />
            
          </div>
        </div>

        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="logo">
              <div className="site-logo">
                <a href="index.html">
                <img src={`images/${imageUrl}.png`} alt="logo" className="img-fluid logo-eve" />
                </a>
              </div>
            </div>
            <div className="main-nav d-none d-lg-block">
              <nav className="site-navigation text-right text-md-center" role="navigation">
                <ul className="site-menu js-clone-nav d-none d-lg-block">
                  <li className="active">
                    <a href="#" onClick={(e) => this.returnHome(e)}>Inicio</a>
                  </li>                
                  <li className="has-children">
                    <a href="#">Categorías</a>
                    <ul className="dropdown">
                      {
                        this.state.categorias.map(e =>
                          {
                            return(
                              <li key={e.categoria}><a href="#" onClick={(ev) => this.getStoreByCategory(ev, e)}>{e.categoria}</a></li>
                            )
                          })
                      }
                    </ul>
                  </li>
                  <li></li>
                </ul>
              </nav>
            </div>
            <div className="icons">
              <a href="#" className="icons-btn d-inline-block js-search-open"><span className="icon-search"></span></a>
              <a href="#" className="icons-btn d-inline-block"><span className="icon-user"></span></a>
              {endSessionBtn}
              <button className="eve-btn-hidden" id="refreshCart" onClick={(e) => this.updateCart(e) }></button>
              <button className="eve-btn-hidden" id="isLoggedIn" onClick={(e) => this.getIfUserIsLogged(e) }></button>
              <a className="eve-btn-hidden" id="productId" onClick={(e) => this.goToProductDetails(e)}></a>
              <a href="#" className="icons-btn d-inline-block bag">
                <span className="icon-shopping-bag" onClick={(e) => this.toggleSidebar(e)}></span>
                <span className="number">{this.state.cartItems}</span>
              </a>
              <a href="#" className="site-menu-toggle js-menu-toggle ml-3 d-inline-block d-lg-none" id="menuTrigger"><span className="icon-menu"></span></a>
            </div>
          </div>
        </div>
      </div>
      <ReactSwipeEvents onSwipedRight={(e) => this.sbSwapped(e)} >
      <Sidebar width={300} height={"100vh"} children={this.state.cartElements} toggle={this.state.showSidebar} />
      </ReactSwipeEvents>
      <a href="https://api.whatsapp.com/send?phone=524776465802&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20la%20tienda." className="float" target="_blank">
        <i className="fa fa-whatsapp my-float"></i>
      </a>
      </div>
  );
  }
}

header.propTypes = {};

header.defaultProps = {};

export default header;
