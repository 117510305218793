import { faBackward, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Component } from 'react'
import ReactImageMagnify from 'react-image-magnify';
import Slider from 'react-slick';
import swal from 'sweetalert';
import environment from '../../environment';
import isEmpty from '../../util/isEmpty';
import CarouselItem from '../HomeComponents/carousel-product';

class ProductSecondaryView extends Component
{
    state={
        productName:"Diademas Roja Perlas",
        imageUrl:"https://eve9accessories.com.mx/assets/images/productdetails/12257-0.jpg",
        precioVenta:119,
        category:"Diademas",
        stock:1,
        productId:12257,
        products:[],
        addingToCart:false,
        quantityToAdd:"1"
    }

    componentDidMount()
    {
        let product = this.props.product;
        this.setState({productName: product.descripcion});
        this.setState({category:product.categoria});
        this.setState({stock:product.stock});
        this.setState({productId:product.idProducto})
        this.setState({precioVenta:product.precio_Venta})
        this.setState({imageUrl:product.urlImagenes.split("|")[0]})
        this.getRecentProducts();
    }

    getRecentProducts = () =>
    {
        Axios.get(environment.getTopProducts)
        .then(response=>
            {
                if(response.data.resultado)
                {
                    this.setState({products: response.data.datos});
                }
            })
            .catch(error=>
                {
                  swal("Error", "Ocurrió un error en la petición: " + error, "error");
                })
    }

    returnToStore()
    {
        this.props.setCurrentInstance("store");
    }

    removeStock(e)
    {
        e.preventDefault();
        let quantity = parseInt(this.state.quantityToAdd);
        if(quantity - 1 <= 0)
        {
            this.setState({quantityToAdd:"0"})
        }
        else
        {
            let newQuantity = quantity - 1;
            this.setState({quantityToAdd:newQuantity})
        }
    }

    addStock(e)
    {
        e.preventDefault();
        let quantity = parseInt(this.state.quantityToAdd);
        let stockAvailable = this.state.stock;
        if(quantity + 1 > stockAvailable)
        {
            swal("Stock excedido", "El limite de stock es de: " + this.state.stock +". Por lo que no es posible agregar más", "error")
        }
        else
        {
            let newQuantity = quantity + 1
            this.setState({quantityToAdd: newQuantity})
        }
    }
   

    addToCart = (e) =>
    {
        e.preventDefault();
        this.setState({addingToCart:true}, ()=>{
            let quantityToAdd = this.state.quantityToAdd;
            let session = document.cookie.split('; ').find(identifier=> identifier.startsWith('session'));
            if(!isEmpty(session))
            {
                let sessionStr= session.split("=")[1];
                let sessionObj = JSON.parse(sessionStr);
                var req = 
                {
                    idCartContent:0,
                    idCart: sessionObj.cartId,
                    idProducto: this.state.productId,
                    quantity:parseInt(quantityToAdd),
                    dateAdded: new Date()
                }
                Axios.post(environment.addItem, req)
                .then(result =>
                    {
                        let response = result.data;
                        if(!response.respuesta.success)
                        {
                            swal("Error al añadir elemento", "Ocurrió un error al agregar al carrito, detalles: " + response.respuesta.userMessage, "error");
                            this.setState({addingToCart:false});
                        }
                        else
                        {
                            swal("Éxito", "Se añadió el producto al carrito correctamente", "success");
                            this.setState({addingToCart:false});
                            let refresher = document.getElementById('refreshCart');
                            refresher.click();
                        }
                    })
                .catch(error =>
                    {
                        swal("Error", "Excepción:" + error, "error")
                    })
                }
            });
        }


    render()
    {
        const loadingIcon =  <FontAwesomeIcon icon={faSpinner} spin />
        let iconSet;
        if(this.state.addingToCart)
        {
            iconSet = loadingIcon;
        }
        else
        {
            iconSet = "";
        }

        var settings = {
            swipeToSlide:true,
            infinite: true,
            speed:2000,
            autoplay:true,
            autoplaySpeed:1500,
            slidesToShow: 5,
            slidesToScroll: 5,
            initialSlide: 1,
            responsive: [
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  infinite: true,
                  dots: true
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3,
                  initialSlide: 2
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  dots:true
                }
              }
            ]
          };

        return(
        <div>
            <div className="eve-bg py-3"  style={{marginTop:"45px"}}>
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 mb-0 eve-color-white">
                    <a href="index.html" className="eve-color-white">Inicio</a> <span className="mx-2 mb-0">/</span> <a href="#" className="eve-color-white">{this.state.category}</a> 
                        <span className="mx-2 mb-0">/</span> <strong className="eve-color-white">{this.state.productName}</strong></div>
                    </div>
                </div>
            </div>  

        <div className="site-section">
        <div className="container">
            <div className="row" style={{margin:"15px"}}>
                <button className="btn btn-outline-primary" style={{borderRadius:"15px"}} onClick={()=>this.returnToStore()}>
                <FontAwesomeIcon icon={faBackward}/> Regresar a la tienda</button>
            </div>
            <div className="row">
            <div className="col-md-6">
                <div className="item-entry">
                    <ReactImageMagnify {...{
                        smallImage: {
                            alt: this.state.productName,
                            isFluidWidth: true,
                            src: this.state.imageUrl
                        },
                        largeImage: {
                            src: this.state.imageUrl,
                            width: 1366,
                            height: 760
                        },
                        enlargedImageContainerStyle:{
                            zIndex:10
                        },
                    }} />
                </div>
            </div>

            <div className="col-md-6">
                <h2 className="text-black">{this.state.productName}</h2>
                <p>Accesorios con una calidad superior</p>
                <p className="mb-4">Recuerda que a partir de 6pz se aplicará el descuento de mayoreo a los productos que lleves con nosotros.</p>
                <p><strong className="text-primary h4">${this.state.precioVenta}.00</strong></p>
                <p className="mb-4">Queda(n) {this.state.stock} disponible(s)</p>
                <input type="text" className="eve-btn-hidden" id="stockAvailable" value={this.state.stock} />
                <div className="mb-5">
                <div className="input-group mb-3" style={{maxWidth:"120px"}}>
                <div className="input-group-prepend">
                    <button className="btn btn-outline-primary" onClick={(e) => this.removeStock(e)} type="button">-</button>
                </div>
                <input type="text" disabled="disabled" className="form-control text-center" value={this.state.quantityToAdd} placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1" />
                <div className="input-group-append">
                    <button className="btn btn-outline-primary" onClick={(e) => this.addStock(e)} type="button">+</button>
                </div>
                </div>

                </div>
                <p>
                <button onClick={(e) => this.addToCart(e)} className="btn btn-outline-primary btn-home" disabled={this.state.addingToCart}>
                   {iconSet}
                    Añadir al carrito</button>
                </p>

            </div>
            </div>
        </div>
        </div>

        <div className="site-section block-3 site-blocks-2">
        <div className="container">
            <div className="row justify-content-center">
            <div className="col-md-7 site-section-heading text-center pt-4">
                <h2>Novedades</h2>
            </div>
            </div>
            <div className="row">
            <div className="col-md-12 block-3">
            <Slider {...settings}>
                {
                    this.state.products.map(product=>
                        {
                            return(
                                <div key={product.urlImagenes}>
                                    <CarouselItem {...product} />
                                </div>
                            )
                        })
                }
                </Slider>
            </div>
            </div>
        </div>
        </div>
    </div>
        )
    }

}

export default ProductSecondaryView;