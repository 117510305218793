

const isEmpty = (any) =>
{
        if(any == "" || any == undefined || any == null)
        {
            return true;
        }
        else
        {
            return false;
        }
}
export default isEmpty;




