import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid/Grid'
import Axios from 'axios';
import environment from '../../environment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import swal from 'sweetalert';

class SiteSection extends Component
{
    state = {
        categories: [],
        isLoading:true
    }
    componentDidMount()
    {
        this.getCategoriesWithImage();
    }

    getCategoriesWithImage = () => 
    {
        Axios.get(environment.getCategories)
        .then(response=>
            {
                this.setState({categories: response.data.datos})
                this.setState({isLoading:false})
            })
        .catch(error=>
            {
                swal("Error", "ocurrió un error con la petición: " + error, "error");
            })
    }

    getStoreByCategory(e, cat)
    {
        this.props.setCategoryFilter(cat);
    }
        
    render()
    {
        const loadingIcon = <FontAwesomeIcon icon={faCircleNotch} spin size='5x' />;
        let loadingItem;
        if(this.state.isLoading)
        {
            loadingItem = loadingIcon;
        }
        else
        {
            loadingItem = "";
        }

        return(
        <div className="site-section">
        <div className="container">
            <div className="title-section mb-5">
            <h2 className="text-uppercase"><span className="d-block">Categorías</span></h2>
            </div>
            <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
                >
            {
              <div className="container" style={{textAlign:'center', color:'#ff9ab0'}}>
              {loadingItem}
              </div>
            }
            {
            this.state.categories.map(e=>
                {
                    return(
                        <Grid key={e.categoria} item xs={4}>
                            <div>
                            <a href="#topnavbar" onClick={(ev) => this.getStoreByCategory(ev, e)}>
                            <img src={e.imagen} alt="product" className="img-fluid" className="product-category" />
                            <p className="eve-p">{e.categoria}</p>
                            </a>
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
        </div>
        </div>
        )
    }
}

export default SiteSection;
