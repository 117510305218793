import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Component } from 'react'
import swal from 'sweetalert'

class PaymentOrder extends Component
{
    state={
        orderDetails:{}
    }

    componentDidMount()
    {
        let dataOrder = this.props.order;
        var order = 
        {
            checkoutOrder:dataOrder.checkoutOrder,
            confirmationEmail: dataOrder.confirmationEmail,
            contactPhone: dataOrder.contactPhone,
            orderExpirationTime: dataOrder.orderExpirationTime,
            totalSale:dataOrder.totalSale
        }
        this.setState({orderDetails: order}, ()=>{
            swal("Completado", "A continuación encontraras los datos de tu orden", "success")
        });
    }

    render()
    {
        return(
            <div>
               <div className="bg-light py-3 confirmed-order-bar">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 mb-0"><a href="index.html" style={{color:"white"}}>Inicio</a> <span className="mx-2 mb-0">/</span> <strong>Orden colocada</strong></div>
                    </div>
                </div>
                </div>  

                <div className="site-section">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 text-center">
                        <span className="icon-check_circle display-3 text-success"></span>
                        <h2 className="display-3 text-black">¡Tu orden fue colocada!</h2>
                        <p>Datos del pedido:</p>
                        <table className="table table-bordered">
                            <thead>
                                <tr><td>Concepto</td><td>Valor</td></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>N° de Orden</td>
                                    <td>000{this.state.orderDetails.checkoutOrder}</td>
                                </tr>
                                <tr>
                                    <td>Total de Orden</td>
                                    <td>${this.state.orderDetails.totalSale}.00</td>
                                </tr>
                                <tr>
                                    <td>Correo electrónico</td>
                                    <td>{this.state.orderDetails.confirmationEmail}</td>
                                </tr>
                                <tr>
                                    <td>Teléfono de confirmación</td>
                                    <td>{this.state.orderDetails.contactPhone}</td>
                                </tr>
                                <tr>
                                    <td>Orden válida hasta</td>
                                    <td>{this.state.orderDetails.orderExpirationTime}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="lead mb-5">Ahora es momento de realizar tu pago, ¡Recuerda! los productos solo serán apartados hasta que realices tu pago</p>
                        <h2>Siguientes pasos</h2>
                        <h5> Deberás realizar los siguientes pasos:</h5>
                        <div className="row">
                            <div className="col-sm-4">
                            <h5>1.- Realizar tu pago</h5>
                            <p>Transferencia Interbancaria</p>
                            <p>Elige BANAMEX como banco receptor, tarjeta <b>5204164903011598</b></p>
                            <p>Depósito Bancario</p>
                            <p>En ventanilla de BANAMEX, tarjeta <b>5204164903011598</b></p>
                            <p>OXXO & 7Eleven</p>
                            <p>Indicar al cajero que es depósito a BANAMEX, tarjeta <b>5204164903011598</b></p>
                            </div>
                            <div className="col-sm-4">
                            <h5>2.- Reportar tu pago</h5>
                            <p> Envíanos tu comprobante de pago vía WhatsApp, haz click 
                            <button className="btn btn-primary"><FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon><a href="https://api.whatsapp.com/send?phone=524776465802&text=quiero%20reportar%20el%20pago%20de%20mi%20orden" target="_blank" style={{color:"white"}}>Reportar</a> </button> para escribirnos
                            </p>
                            </div>
                            <div className="col-sm-4">
                            <h5>3.- Esperar tu pedido</h5>
                            <p>Una vez confirmado se te dará la información y contacto vía WhatsApp. También puedes consultar tus datos en la sección de tu cuenta. Cualquier detalle contáctanos al <a href="https://api.whatsapp.com/send?phone=524776465802&text=tengo%20un%20problema%20con%20mi%20orden" target="_blank">Servicio al cliente</a>
                            </p>
                            <a href="index.html" className="btn btn-sm height-auto px-4 py-3 btn-primary">Finalizar</a>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
            </div>
        )
    
    }
        
}

export default PaymentOrder