import Axios from 'axios';
import React, {Component} from 'react';
import swal from 'sweetalert';
import environment from '../../environment';
import CarouselItem from '../HomeComponents/carousel-product';
import Grid from '@material-ui/core/Grid/Grid'
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faFilter, faSpinner, faStar, faTable } from '@fortawesome/free-solid-svg-icons';
import isEmpty from '../../util/isEmpty';

class Store extends Component 
{
    state = {
        productList:[],
        cacheProducts:[],
        persistanceProducts:[],
        categories:[],
        activePage:1,
        itemsPerPage:10,
        productLength:0,
        currentPageLowerLimit:0,
        loading:true,
        loadingSidebar:true,
        defaultGridSize:6,
        categoryHomeFilter:"",
        homeLabelFilter:""
    }

    handlePageChange(pageNumber) {
      this.setState({activePage: pageNumber});
      let productPage = [...this.state.cacheProducts];
      this.setState({productLength:productPage.length})
      pageNumber = pageNumber - 1;
      let lowerLimit = pageNumber * this.state.itemsPerPage;
      this.setState({productList: productPage.splice(lowerLimit, this.state.itemsPerPage)});
    }
    
    componentDidMount()
    {
      let automaticFilter = this.props.category;
      let query = this.props.searchTerm;
      if(!isEmpty(automaticFilter))
      {
        this.setState({categoryHomeFilter: automaticFilter.categoria})
      }
      if(isEmpty(query))
      {
        this.getItemsStore();
      }
      else
      {
        this.getFilteredItemsByQuery(query);
      }
     
      this.getCategoriesWithImage();
    }

    getFilteredItemsByQuery = (query) =>
    {
      var req = 
      {
        query:query
      }
      Axios.post(environment.getQueryProducts, req)
        .then(response=>
            {
                if(!response.data.resultado)
                {
                    swal("Error de obtención", "Ocurrió un error al obtener los productos", "error");
                }
                else
                {
                    let data = response.data.datos;
                    this.setState({homeLabelFilter:" / " + query});
                    this.setState({cacheProducts:data}, ()=>
                    {
                      this.handlePageChange(1);
                    })
                    this.setState({persistanceProducts:[...data]});
                    this.setState({productLength:data.length});
                    this.setState({loading:false});
                }
            }).catch(error=>
              {
                swal("Error de comunicación", "Ocurrió un error al solicitar los datos al servidor: " + error, "error");
              });
    }

    updateOutsideCategory = (cat) =>
    {
      let category = cat.categoria;
      let completeList = [...this.state.persistanceProducts];
      completeList = completeList.filter(a => a.categoria == category);
      this.setState({homeLabelFilter:" / " + cat.categoria})
      this.setState({cacheProducts:completeList}, ()=>
      {
        this.handlePageChange(1);
      })
    }

    getCategoriesWithImage = () => 
    {
        Axios.get(environment.getCategories)
        .then(response=>
            {
                this.setState({categories: response.data.datos});
                this.setState({loadingSidebar:false});
            })
        .catch(error=>
            {
                swal("Error", "Ocurrió un error con la petición: " + error, "error");
            })
    }
    setColumnsGrid(event, cols)
    {
      event.preventDefault();
      if(cols == 2)
      {
        this.setState({defaultGridSize:6})
        this.setState({itemsPerPage:10}, ()=>
        {
          this.handlePageChange(this.state.activePage);
        })
      }
      else if(cols == 4)
      {
        this.setState({defaultGridSize:3});
        this.setState({itemsPerPage:16}, ()=>
        {
          this.handlePageChange(this.state.activePage);
        })
      }
    }

    applyFilter(filter)
    {
      let filteredList = [...this.state.cacheProducts]
      switch(filter)
      {
        case 'rel':
          this.setState({homeLabelFilter:" / Reelevantes"});
          this.setState({cacheProducts:this.state.persistanceProducts}, ()=>
          {
            this.handlePageChange(1);
          });
          break;
        case 'az':
          this.setState({homeLabelFilter:" / Alfabético A - Z"});
          filteredList = filteredList.sort(this.compare)
          this.setState({cacheProducts:filteredList}, ()=>
          {
            this.handlePageChange(1);
          });
          break;
        case 'za':
          this.setState({homeLabelFilter:" / Alfabético Z - A"});
          filteredList = filteredList.reverse(this.compareInv);
          this.setState({cacheProducts:filteredList}, ()=>
          {
            this.handlePageChange(1);
          });
          break;
      }
      
    }

    getCategory = (e, cat) =>
    {
      e.preventDefault();
      let category = cat.categoria;
      let completeList = [...this.state.persistanceProducts];
      completeList = completeList.filter(a => a.categoria == category);
      this.setState({homeLabelFilter:" / " + cat.categoria})
      this.setState({cacheProducts:completeList}, ()=>
      {
        this.handlePageChange(1);
      })

    }

    compare(a, b)
    {
      const itm1 = a.descripcion;
      const itm2 = b.descripcion;
      let comparison = 0;
      if(itm1 > itm2)
      {
        comparison = 1;
      }
      else if(itm1 < itm2)
      {
        comparison = -1
      }
      return comparison;
    }

    compareInv(a, b)
    {
      const itm1 = a.descripcion;
      const itm2 = b.descripcion;
      let comparison = 0;
      if(itm1 > itm2)
      {
        comparison = 1;
      }
      else if(itm1 < itm2)
      {
        comparison = -1
      }
      return comparison * -1;
    }

    getItemsStore = () => 
    {
        Axios.get(environment.getAllStore)
        .then(response=>
            {
                if(!response.data.resultado)
                {
                    swal("Error de obtención", "ocurrió un error al obtener los productos", "error");
                }
                else
                {
                    let data = response.data.datos;
                    let dataFiltered = [...data];
                    let firstPage;
                    if(!isEmpty(this.state.categoryHomeFilter))
                    {
                      this.setState({homeLabelFilter:" / " + this.state.categoryHomeFilter});
                      dataFiltered = dataFiltered.filter(a => a.categoria == this.state.categoryHomeFilter);
                      this.setState({cacheProducts:dataFiltered}, ()=>
                      {
                        this.handlePageChange(1);
                      })
                    }
                    else{
                      firstPage = data.slice(0, this.state.itemsPerPage);
                      this.setState({productList:firstPage});
                      this.setState({cacheProducts:[...data]});
                    }
                    
                    this.setState({persistanceProducts:[...data]});
                    this.setState({productLength:data.length});
                    this.setState({loading:false});
                }
            })
        .catch(error=>
            {
              swal("Error en obtención", "Excepción ocurrida:" + error, "error")
            })
    }
    render()
    {
      const loadingBar = <FontAwesomeIcon icon={faCircleNotch} spin size='10x' />;
      const loadingSideBar = <FontAwesomeIcon icon={faCircleNotch} spin size='5x' />;
      let loadingIcon;
      let loadingSideIcon;
        if(this.state.loading)
        {
          loadingIcon = loadingBar
        }
        else
        {
          loadingIcon = "";
        }

        if(this.state.loadingSidebar)
        {
          loadingSideIcon = loadingSideBar;
        }
        else
        {
          loadingSideIcon = "";
        }

        return(
        
            
    <div className="site-section" style={{marginTop:"35px"}}>
      <div className="container">

        <div className="row mb-5">
          <div className="col-md-9 order-1">

            <div className="row align" style={{backgroundColor:"#ff9ab08c"}}>
              <div className="col-md-12 mb-5">
                <div className="float-md-left"><h2 className="text-white">Tienda {this.state.homeLabelFilter}</h2></div>
                <div className="d-flex">
                  <div className="dropdown mr-1 ml-md-auto">
                    <button type="button" className="btn btn-white btn-sm dropdown-toggle px-4 eve-store-filter-btn" id="dropdownMenuOffset" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <FontAwesomeIcon icon={faStar} size='1x' />
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuOffset">
                      <a className="dropdown-item" href="#">Mas vendidos</a>
                    </div>
                  </div>
                  <div className="btn-group">
                    <button type="button" className="btn btn-white btn-sm dropdown-toggle px-4 eve-store-filter-btn" id="dropdownMenuReference" data-toggle="dropdown">
                      <FontAwesomeIcon icon={faFilter} size='1x' />
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuReference">
                      <a className="dropdown-item" href="#" onClick={(e)=>this.applyFilter('rel')}>Más Reelevantes</a>
                      <a className="dropdown-item" href="#" onClick={(e)=>this.applyFilter('az')}>Alfabético, A - Z</a>
                      <a className="dropdown-item" href="#" onClick={(e)=>this.applyFilter('za')}>Alfabético, Z - A</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#">Precio, menor a mayor</a>
                      <a className="dropdown-item" href="#">Precio, mayor a menor</a>
                    </div>
                  </div>
                  <div className="btn-group">
                    <button type="button" className="btn btn-white btn-sm dropdown-toggle px-4 grid-adjustment eve-store-filter-btn" id="dropdownMenuGrid" data-toggle="dropdown" >
                      <FontAwesomeIcon icon={faTable} size='1x' /> 
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuGrid">
                      <a className="dropdown-item" href="#" onClick={(e)=>this.setColumnsGrid(e, 2)}>2 Columnas</a>
                      <a className="dropdown-item" href="#" onClick={(e)=>this.setColumnsGrid(e, 4)}>4 Columnas</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
            {
              <div className="container" style={{textAlign:'center', color:'#ff9ab0'}}>
              {loadingIcon}
              </div>
            }
            <Grid container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={2}
                > 
                 {
                this.state.productList.map(e =>
                  {
                    return(
                      <Grid key={e.idProducto} item xs={this.state.defaultGridSize}>
                          <CarouselItem {...e} />        
                      </Grid>
                    )
                  })
              }
                </Grid>
             
              
            </div>
            <div className="row">
              <div className="col-md-12 text-center">
                  <div className="site-block-27">
                    <Pagination 
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsPerPage}
                      totalItemsCount={this.state.productLength}
                      pageRangeDisplayed={3}
                      onChange={this.handlePageChange.bind(this)}
                    />
                  </div>
                </div>
            </div>
          </div>

          <div className="col-md-3 order-2 mb-5 mb-md-0 categoryBlock">
            <div className="border p-4 rounded mb-4">
              <h3 className="mb-3 h6 text-uppercase text-black d-block">Categorias</h3>
              {
                <div className="container" style={{textAlign:'center', color:'#ff9ab0'}}>
                  {loadingSideIcon}
                </div>
              }
              <ul className="list-unstyled mb-0">
               
               {
                 this.state.categories.map(entity =>
                  { 
                    return(
                      <li key={entity.categoria} className="mb-1"><a href="#" onClick={(e)=>this.getCategory(e, entity)} className="d-flex"><span>{entity.categoria}</span> <span className="text-black ml-auto">({entity.cantidad})</span></a></li>
                    )
                  })
               }
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>


        )
    }
}

export default Store;