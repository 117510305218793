

// const baseApi = "https://192.168.1.103:44383/"
// const baseApi = "https://localhost:44383/";
const baseApi = "/back/";


const environment = 
{
    // Starting section
    getProducts: baseApi + "api/products/list",
    getTopProducts: baseApi + "api/products/top-products",
    getCategories: baseApi + "api/products/categories",

    // Cart section
    setCart: baseApi + "api/carts/new-cart",
    getCart: baseApi + "api/carts/current-cart",
    addItem: baseApi + "api/carts/add-product",
    editItem: baseApi + "api/carts/edit-product",
    deleteItem: baseApi + "api/carts/remove-product",

    // Store section
    getStoreFirst: baseApi + "api/store/top",
    getAllStore: baseApi + "api/store/all",
    getQueryProducts: baseApi + "api/store/products",

    // Checkout section
    placePaymentOrder: baseApi + "api/checkout/payment-order",
    placePayPalOrder: baseApi + "api/checkout/paypal-order",
    setPayPalData: baseApi + "api/checkout/paypal-data",

    // Authentication section 
    login: baseApi + "api/users/login",
    getShipmentDetails: baseApi + "api/users/user",
    
    //Business
    payPalComission: 0.05
}

export default environment;