import $ from 'jquery';

const mobileMenuWrapper = () => 
{
    siteMenuClone();
}

var siteMenuClone = function() {

    $('<div class="site-mobile-menu"></div>').prependTo('.site-wrap');

    $('<div class="site-mobile-menu-header"></div>').prependTo('.site-mobile-menu');
    $('<div class="site-mobile-menu-close "></div>').prependTo('.site-mobile-menu-header');
    $('<div class="site-mobile-menu-logo"></div>').prependTo('.site-mobile-menu-header');

    $('<div class="site-mobile-menu-body"></div>').appendTo('.site-mobile-menu');

    
    var menuHeader = '<a href="#" className="js-logo-clone">EVE 9 Accessories</a>' + 
                        '<div class="container  " style="text-align:right;"><button style="border-radius:15px;" onClick=(document.getElementById("menuTrigger").click())>X</button></div>';
    $(menuHeader).clone().appendTo('.site-mobile-menu-logo');

    $('<span class="ion-ios-close js-menu-toggle"></div>').prependTo('.site-mobile-menu-close');
    

    $('.js-clone-nav').each(function() {
        var $this = $(this);
        $this.clone().attr('class', 'site-nav-wrap').appendTo('.site-mobile-menu-body');
    });

}; 

export default mobileMenuWrapper;