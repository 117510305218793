import React, {Component} from 'react';
import styles from './home.module.css';
import SiteSection from '../HomeComponents/site-section'

class Home extends Component 
{
  state = {}

  componentDidMount()
  {

  }

  switchStore = (e) => 
  {
    e.preventDefault();
    this.props.setCurrentInstance("store");
  }
  render()
  {
    let imageUrl = "cover";
    return(
      <div className="site-blocks-cover" data-aos="fade" style={{marginLeft:"16px"}}>
      <div className="container">
        <div className="row eve-bg">
          <div className="col-md-6 ml-auto order-md-2 align-self-start">
            <div className="site-block-cover-content">
            <h2 className="sub-title eve-color-white">Accessorios al mejor precio</h2>
            <h1 className="eve-color-white">Mayoreo a partir de 6 piezas</h1>
            <p><a href="#" className="btn eve-btn" onClick={(e) => this.switchStore(e)}>Compra ahora</a></p>
            </div>
          </div>
          <div className="col-md-6 order-1 align-self-end">
            <img src={`images/${imageUrl}.jpg`} alt="Image" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
    );
  }
}

Home.propTypes = {};

Home.defaultProps = {};

export default Home;
