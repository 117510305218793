import axios from 'axios'
import swal from 'sweetalert';
import environment from '../environment';
import isEmpty from './isEmpty'

const SessionCart = () => 
{
    const currentCookie = document.cookie?.split('; ').find(identifier=> identifier.startsWith('session'));

    if(isEmpty(currentCookie))
    {
        axios.get('https://www.cloudflare.com/cdn-cgi/trace')
            .then(result=>
                {
                    let items = result.data.split(/\n/);
                    let ip = items[2].split("=")[1]
                    let keyDate = new Date();
                    let cookieKey = (keyDate.getTime() / 1000) + ip;
                    let expiration = new Date(keyDate.getTime() + 60*60000).toUTCString();
                    let request = 
                    {
                        sessionId: cookieKey,
                        loggedSession: 0
                    }
                    axios.post(environment.setCart, request)
                        .then(result=>
                            {
                                if(!result.data.resultado)
                                {
                                    swal("Error de asignación", "No se pudo dar de alta el carrito de esta sesión", "error");
                                }
                                else
                                {
                                    let sessionData = 
                                    {
                                        sessionId: cookieKey,
                                        cartId: result.data.datos.idCart
                                    }
                                    let cookieToAdd = "session=" + JSON.stringify(sessionData) + "; expires=" + expiration +"; path=/";
                                    document.cookie = cookieToAdd;
                                }
                            })
                        .catch(error=>
                            {
                                swal("Error al asignar carrito", "Excepción: " + error, "error")
                            })
            })
            .catch(error=>
                {
                    swal("Error", "ocurrió un error en la petición: " + error, "error");
                })
    }
}

export default SessionCart;