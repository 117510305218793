
const swalQuestion = (content) => 
{
    let configuration = 
    {
        title: "Confirmar acción", 
        text: content,
        icon: "warning",
        buttons:true,
        dangerMode: true
    }
    return configuration;
}

export default swalQuestion;