import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import swal from 'sweetalert';
import environment from '../../environment';
import isEmpty from '../../util/isEmpty';
import swalQuestion from '../../util/swalQuestion';
import LoginModal from '../login/user-login';
import PaypalButton from '../paypal/paypal';

class Checkout extends Component 
{
    state = {
        assignedSession: "", 
        cartItems: [],
        cartId:0,
        mayoreo: 0,
        total:0,
        subtotal:0,
        discount:0,
        placePayment:false,
        firstName:"",
        lastName:"",
        companyName:"",
        address:"",
        secondaryAddress:"",
        state:"",
        cp:"",
        email:"",
        phone:"",
        orderNotes:"",
        createAccount:false,
        accountPassword:"",
        differentAddress:false,
        altFirstName:"",
        altLastName:"",
        altAddress:"",
        altSecondaryAddress:"",
        altState:"",
        altCp:"",
        altEmail:"",
        altPhone:"",
        formDisabled:"",
        isCreatingOrder:false,
        isLoggedIn:false,
        isPreloadedAddress:false,
        JWT:"",
        ShipmentCostApplied:false,
        payPalComission:false
    }

    
    finalizePurchasePayPal = (data) =>
    {
        let orderData = 
        {
            orderId:data.orderID,
            payerId:data.payerID,
            totalOrder:this.state.total
        }
        let serializedResponse = JSON.stringify(orderData);
        let req = 
        {
            responseSerialized: serializedResponse,
            issuer:"PayPal",
            jwt: this.state.JWT,
            idCart: this.state.cartId
        }
        Axios.post(environment.setPayPalData, req)
        .then(result=>{
            debugger
            if(result.data.resultado)
            {
                var sessionToken = this.state.JWT != "" ? this.state.JWT : "";
                var req = 
                {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    companyName: this.state.companyName,
                    address: this.state.address,
                    secondaryAddress: this.state.secondaryAddress,
                    state: this.state.state,
                    cp: this.state.cp,
                    email: this.state.email,
                    phone: this.state.phone,
                    orderNotes: this.state.orderNotes,
                    isAlternativeSending: this.state.differentAddress,
                    createAccount: this.state.createAccount,
                    username: this.state.email,
                    password: this.state.accountPassword,
                    altFirstName: this.state.altFirstName,
                    altLastName: this.state.altLastName,
                    AltCompanyName:"",
                    altAddress: this.state.altAddress, 
                    altSecondaryAddress: this.state.altSecondaryAddress,
                    altState: this.state.altState,
                    altCp: this.state.altCp,
                    altEmail: this.state.altEmail, 
                    altPhone: this.state.altPhone,
                    cartId: this.state.cartId,
                    sessionToken: sessionToken,
                    orderId:data.orderID,
                    isNationalShipment:this.state.ShipmentCostApplied
                }
                Axios.post(environment.placePayPalOrder, req)
                    .then(result=> {
                        this.props.switchPayPalPayment(result.data.datos)
                    }).catch(error=>{
                        swal("Error", "ocurrió un error en la conectividad", "error")
                    })
            }
            else
            {
                swal("Error en registro de pago", "Error en registro de pago, consulte al administrador", "error");
            }
            
        })
        .catch(error=>
            {
                swal("Error", "ocurrió un error en la conectividad:" + error, "error")
            })
    }

    placePaymentOrder = (e) =>
    {
        e.preventDefault();
        this.setState({isCreatingOrder:true}, ()=>
        {
            var sessionToken = this.state.JWT != "" ? this.state.JWT : "";
            var req = 
            {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                companyName: this.state.companyName,
                address: this.state.address,
                secondaryAddress: this.state.secondaryAddress,
                state: this.state.state,
                cp: this.state.cp,
                email: this.state.email,
                phone: this.state.phone,
                orderNotes: this.state.orderNotes,
                isAlternativeSending: this.state.differentAddress,
                createAccount: this.state.createAccount,
                username: this.state.email,
                password: this.state.accountPassword,
                altFirstName: this.state.altFirstName,
                altLastName: this.state.altLastName,
                AltCompanyName:"",
                altAddress: this.state.altAddress,
                altSecondaryAddress: this.state.altSecondaryAddress,
                altState: this.state.altState,
                altCp: this.state.altCp,
                altEmail: this.state.altEmail, 
                altPhone: this.state.altPhone,
                cartId: this.state.cartId,
                sessionToken: sessionToken,
                orderId:"",
                isNationalShipment:this.state.ShipmentCostApplied
            }
            Axios.post(environment.placePaymentOrder, req)
                .then(response=>
                    {
                        console.log(response)
                        this.setState({isCreatingOrder:false}, ()=>
                        {
                            this.props.switchPaymentOrderInstance(response.data.datos)
                        });
                    })
                .catch(error=>
                    {
                        swal("Error", "Error: " + error, "error");
                    })
        });
    }
    updateTotalWithShipment = (e) =>
    {
        e.persist();
        let option = e.target.value
        switch(option)
        {
            case "2":
                if(!this.state.ShipmentCostApplied){
                    this.setState({ShipmentCostApplied:true})    
                    let total = this.state.total;
                    total += 150;
                    this.setState({total: total}, ()=>
                    {
                        swal("Envío agregado", "Se agregó el costo del envío", "success");
                    });
                }
                break;
            case "1":
                if(this.state.ShipmentCostApplied)
                {
                    let total = this.state.total;
                    total = total - 150;
                    this.setState({ShipmentCostApplied:false}, ()=>{
                        this.setState({total:total}, ()=>
                        {
                            swal("Costo de envío retirado", "Se removió el costo de envío de tu cuenta a pagar, sin embargo, deberás pagar la tarifa entre $30 y $80 pesos al repartidor", "success");
                        })
                    }) 
                }
                else{
                    swal("Envío local agregado", "Deberás pagar en efectivo al repartidor la tarifa entre $30 y $80 pesos", "success")
                }
                break;
            default:
                if(this.state.ShipmentCostApplied)
                {
                    let total = this.state.total;
                    total = total - 150;
                    this.setState({ShipmentCostApplied:false}, ()=>{
                        this.setState({total:total}, ()=>
                        {
                            swal("Envío retirado", "Se removió el costo del envío", "success");
                        })
                    })
                    
                }
                break;
        }
    }
    handleFormChange = (event) =>
    {
        event.persist();
        this.setState({[event.target.name] : event.target.value});
    }

    handleCheckboxChange = (event) =>
    {
        event.persist();
        this.setState({[event.target.name] : event.target.checked});
    }

    setPaymentMethod = (e) =>
    {
        e.preventDefault();
        let formIsValid = this.validateForm();
        if(formIsValid.valid)
        {
            swal(swalQuestion('¿Deseas confirmar tus datos? ya no podrán editarse posteriormente')).then(
                result=>
                {
                    if(result)
                    {   
                        this.setState({placePayment:true}, ()=>
                        {
                            this.setState({formDisabled:"disabled"});
                            swal("Datos confirmados", "Ahora debes indicar tu método de pago a continuación para terminar la orden.", "success")
                        });
                    }
                })
        }
        else 
        {
            let fields = "";
            formIsValid.fields.forEach(element=>
                {
                    fields += element + ", ";
                })
            swal("Formulario incompleto", "Campos faltantes: " + fields.substring(0, fields.length -2), "error")
        }
    }

    togglePayPalCommission(e)
    {
        e.persist();
        let val = e.target.value;
        let comision = this.state.subtotal * environment.payPalComission;
        comision += 4;
        comision = Math.floor(comision);
        if(val === "1" && this.state.payPalComission)
        {
            this.setState({payPalComission:false}, ()=>
            {
                swal("Sin comisiones", "En pago por transferencia o depósito no aplican comisiones", "info")
                let total = this.state.total
                total = total - comision;
                this.setState({total: total});
            })
        }
        else if(val === "2" && !this.state.payPalComission)
        {
            this.setState({payPalComission:true}, ()=>
            {
                swal("Aplican comisiones", "En pago por PayPal aplicará una comisión de: " + comision +" MXN al total de la orden", "info")
                let total = this.state.total
                total += comision;
                this.setState({total: total});
            })

        }

    }
    validateForm()
    {
        let response = 
        {
            valid:true,
            fields:[]
        }
        const {firstName, lastName,address,state,cp,email,phone,createAccount,accountPassword,differentAddress,
        altFirstName,altLastName,altAddress,altState,altCp,altPhone} = this.state;

        if(isEmpty(firstName))
        {
            response.fields.push("Nombre(s)")
            response.valid = false;
        }
        if(isEmpty(lastName))
        {
            response.fields.push("Apellidos")
            response.valid = false;
        }    
        if(isEmpty(address))
        {
            response.fields.push("Domicilio")
            response.valid = false;
        }    
        if(isEmpty(state))
        {
            response.fields.push("Estado")
            response.valid = false;
        }
        if(isEmpty(cp))
        {
            response.fields.push("Código postal")
            response.valid = false;
        }        
        if(isEmpty(email))
        {
            response.fields.push("Correo electrónico")
            response.valid = false;
        }        
        if(isEmpty(phone))
        {
            response.fields.push("Teléfono")
            response.valid = false;
        }           
        if(createAccount)
        {
            if(isEmpty(accountPassword))
            {
                response.fields.push("Contraseña de la cuenta")
                response.valid = false;
            }
        }
        if(differentAddress)
        {
            if(isEmpty(altFirstName))
            {
                response.fields.push("Nombre(s) del destinatario")
                response.valid = false;
            }
            if(isEmpty(altLastName))
            {
                response.fields.push("Apellidos del destinatario")
                response.valid = false;
            }    
            if(isEmpty(altAddress))
            {
                response.fields.push("Domicilio del destinatario")
                response.valid = false;
            }    
            if(isEmpty(altState))
            {
                response.fields.push("Estado del destinatario")
                response.valid = false;
            }
            if(isEmpty(altCp))
            {
                response.fields.push("Código postal del destinatario")
                response.valid = false;
            }        
            if(isEmpty(altPhone))
            {
                response.fields.push("Teléfono del destinatario")
                response.valid = false;
            }           
        }
        return response;
    }

    returnToCheckoutInstance()
    {
        let modal = document.getElementsByClassName('swal-overlay')
        modal[0].style.display = "none"
    }

    loggedInSuccessfull = (jwt) => 
    {
        if(!isEmpty(jwt))
        {
            var setSession = document.getElementById('isLoggedIn')
            setSession.click();
            this.setState({JWT:jwt}, ()=>{
                this.setState({isLoggedIn:true}, ()=>{
                    
                    const config = {
                        headers: { Authorization: `Bearer ${this.state.JWT}` }
                    };
                    var req = 
                    {
                        expiration:new Date(),
                        token:this.state.JWT
                    }

                    Axios.post(environment.getShipmentDetails, req, config)
                    .then(result=>
                        {
                            let response = result.data.datos;
                            if(isEmpty(response))
                            {
                                swal("Sin datos para envio", "No hay datos registrados para tu envío, por favor llena el formulario", "info");
                            }
                            else
                            {
                                this.setState({isPreloadedAddress:true});
                                this.setShipmentDataPreloaded(response)
                            }
                        })
                    .catch(error=>{
                        swal("Error", "Error: " + error, "error")
                    });
                });
            })
        }
    }

    showLoginModal = (e) =>
    {
        e.preventDefault();
        let wrapper = document.createElement('div');
        ReactDOM.render(<LoginModal returnToCheckoutInstance={this.returnToCheckoutInstance} loggedInSuccessfull={this.loggedInSuccessfull}  />, wrapper);
        let md = wrapper.firstChild;
        swal({
            text:"Iniciar sesión",
            icon:"info",
            buttons:{
                cancel: {
                  text: "Cancel",
                  value: null,
                  visible: false,
                  className: "",
                  closeModal: true,
                },
                confirm: {
                  text: "Ok",
                  value: true,
                  visible: false,
                  className: "",
                  closeModal: true
                }
              },
            content: md
        })
        let modal = document.getElementsByClassName('swal-overlay')
        modal[0].style.display = "block"
    }

    setShipmentDataPreloaded(shipment)
    {
        this.setState({firstName:shipment.firstName});
        this.setState({lastName:shipment.lastName});
        this.setState({companyName:shipment.companyName});
        this.setState({address:shipment.address});
        this.setState({secondaryAddress:shipment.secondaryAddress});
        this.setState({state:shipment.state});
        this.setState({cp:shipment.cp});
        this.setState({email:shipment.email});
        this.setState({phone:shipment.phone});
        this.setState({differentAddress:shipment.isAlternativeSending});
        this.setState({altFirstName:shipment.altFirstName});
        this.setState({altLastName:shipment.altLastName});
        this.setState({altAddress:shipment.altAddress});
        this.setState({altSecondaryAddress:shipment.altSecondaryAddress});
        this.setState({altState:shipment.altState});
        this.setState({altCp:shipment.altCp});
        this.setState({altEmail:shipment.altEmail});
        this.setState({altPhone:shipment.altPhone});
        this.setState({orderNotes:shipment.orderNotes});
    }
   
    componentDidMount()
    {
        let cookieSession =  document.cookie.split('; ').find(identifier=> identifier.startsWith('session'))
        this.setState({assignedSession:cookieSession}, ()=>
        {
            let sessionStr= this.state.assignedSession.split("=")[1];
            let sessionObj = JSON.parse(sessionStr);
            if(!isEmpty(sessionObj.jwt))
            {
                this.setState({JWT:sessionObj.jwt}, ()=>{
                    this.setState({isLoggedIn:true}, ()=>{
                        
                        const config = {
                            headers: { Authorization: `Bearer ${this.state.JWT}` }
                        };
                        var req = 
                        {
                            expiration:new Date(),
                            token:this.state.JWT
                        }

                        Axios.post(environment.getShipmentDetails, req, config)
                        .then(result=>
                            {
                                let response = result.data.datos;
                                if(isEmpty(response))
                                {
                                    swal("Sin datos para envio", "No hay datos registrados para tu envío, por favor llena el formulario", "info");
                                }
                                else
                                {
                                    this.setState({isPreloadedAddress:true});
                                    this.setShipmentDataPreloaded(response)
                                }
                            })
                        .catch(error=>{
                            swal("Error", "Error: " + error, "error")
                        });
                    });
                })
            }
            let req = 
            {
              sessionId: sessionObj.sessionId,
              loggedSession: 0
            }

            Axios.post(environment.getCart, req).then(response=>
                {
                    let data = response.data;
                    if(!data.respuesta.success)
                    {
                        swal("Error", "Ocurrió un error con la respuesta del servidor: " + data.respuesta.userMessage, "error");
                    }
                    else
                    {
                        let countItems = 0;
                        let discountPrice = 0;
                        let regularPrice = 0;
                        let products = data.datos;
                        let cartId = products.length > 0 ? products[0].idCart : 0;
                        this.setState({cartId: cartId})
                        products.forEach(element => {
                            countItems += parseInt(element.cantidad);
                            discountPrice += parseInt(Math.ceil(element.precioMayoreo)) * parseInt(element.cantidad);
                            regularPrice += parseInt(element.precio_Venta) * parseInt(element.cantidad);
                        });
                        if(countItems >= 6){
                            this.setState({mayoreo:1}, ()=>
                            {
                                let discountApplied = regularPrice - discountPrice;
                                this.setState({total:discountPrice})
                                this.setState({subtotal:discountPrice})
                                this.setState({discount: discountApplied})
                                
                            }) 
                        }
                        else
                        {   
                            this.setState({total:regularPrice})
                            this.setState({subtotal:regularPrice})
                        }

                        this.setState({cartItems:products})
                    }
                })
                .catch(error=>
                    {
                        swal("Error", "Ocurrió un error con la petición: " + error, "error");
                    })
            
        })
    }

    render()
    {
        const placePayment = this.state.placePayment;
        const isPayPalMethod = this.state.payPalComission;
        const isLoggedIn = this.state.isLoggedIn;
        let discountTap;
        let payPalBtn;
        let isLoadingPaymentOrder;

        if(this.state.discount > 0)
        {
            discountTap = <tr><td className="text-black font-weight-bold"><strong>Descuento</strong></td>
            <td className="text-black">${this.state.discount}</td></tr>
        }
        else
        {
            discountTap = <tr style={{display:"none"}}><td></td></tr>;
        }

        if(this.state.total > 0)
        {
            payPalBtn = <PaypalButton finalizePurchasePayPal={this.finalizePurchasePayPal} amount={this.state.total} />
        }
        else
        {   
            payPalBtn = <FontAwesomeIcon icon={faCircleNotch} spin size='5x' />
        }
        if(this.state.isCreatingOrder)
        {
            isLoadingPaymentOrder = <FontAwesomeIcon icon={faCircleNotch} spin size='2x' >Finalizar orden</FontAwesomeIcon>
        }
        else 
        {
            isLoadingPaymentOrder = <p>Finalizar orden</p>
        }

        return(
            <div>
                <div className="row eve-bg" style={{marginTop:"45px"}}>
                    <div className="col-md-5" style={{textAlign:"center"}}>
                        <h1 className="eve-color-white">Checkout</h1>
                    </div>
                </div>
                <div className="row" style={{textAlign:"center"}}>
                    <div className="col-md-10">
                    <h4>Antes de continuar te pedimos que revises que tu pedido esté correcto</h4>
                    </div>
                </div>
                <div className="site-section">
                <div className="container">
                {!isLoggedIn && (
                    <div className="row mb-5">
                    <div className="col-md-12">
                        <div className="border p-4 rounded" role="alert">
                            <div>¿Ya tienes una cuenta? <a href="#" onClick={(e) => this.showLoginModal(e)}>Click aquí</a> para iniciar sesión</div>
                        </div>
                    </div>
                    </div>
                    )}
                    <div className="row">
                    <div className="col-md-6 mb-5 mb-md-0">
                        <h2 className="h3 mb-3 text-black">Detalles de Facturación</h2>
                        <div className="p-3 p-lg-5 border">
                        <div className="form-group row">
                        
                            <div className="col-md-6">
                            <label className="text-black">Nombre(s) <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="c_fname" name="firstName" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)} 
                            value={this.state.firstName}/>
                            </div>
                            <div className="col-md-6">
                            <label className="text-black">Apellido(s) <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="c_lname" name="lastName" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)}
                            value={this.state.lastName} />
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                            <label htmlFor="c_companyname" className="text-black">Empresa </label>
                            <input type="text" className="form-control" id="c_companyname" name="companyName" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)} 
                            value={this.state.companyName}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-12">
                            <label htmlFor="c_address" className="text-black">Domicilio <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="c_address" name="address" disabled={this.state.formDisabled} placeholder="Calle y número" 
                            onChange={(e) => this.handleFormChange(e)} value={this.state.address}/>
                            </div>
                        </div>

                        <div className="form-group">
                            <input type="text" className="form-control" name="secondaryAddress" disabled={this.state.formDisabled} 
                            placeholder="Apartamento, suite, edificio etc. (opcional)" onChange={(e) => this.handleFormChange(e)} 
                            value={this.state.secondaryAddress}/>
                        </div>

                        <div className="form-group row">
                            <div className="col-md-6">
                            <label htmlFor="c_state_country" className="text-black"> Estado <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="c_state_country" disabled={this.state.formDisabled} name="state" 
                            onChange={(e) => this.handleFormChange(e)}  value={this.state.state} />
                            </div>
                            <div className="col-md-6">
                            <label htmlFor="c_postal_zip" className="text-black">Código Postal <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="c_postal_zip" name="cp" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)}
                             value={this.state.cp} />
                            </div>
                        </div>

                        <div className="form-group row mb-5">
                            <div className="col-md-6">
                            <label htmlFor="c_email_address" className="text-black">Correo electrónico <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="c_email_address" name="email" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)}
                            value={this.state.email} />
                            </div>
                            <div className="col-md-6">
                            <label htmlFor="c_phone" className="text-black">Teléfono <span className="text-danger">*</span></label>
                            <input type="text" className="form-control" id="c_phone" name="phone" placeholder="Número telefónico" disabled={this.state.formDisabled} 
                            onChange={(e) => this.handleFormChange(e)} value={this.state.phone} />
                            </div>
                        </div>

                        {!isLoggedIn && 
                        (
                            <div className="form-group">
                            <label htmlFor="c_create_account" className="text-black" data-toggle="collapse" href="#create_an_account" role="button" aria-expanded="false" aria-controls="create_an_account">
                                <input type="checkbox" value="1" id="c_create_account" name="createAccount" disabled={this.state.formDisabled} onChange={(e) => this.handleCheckboxChange(e)}/> Crear tu cuenta?</label>
                            <div className="collapse" id="create_an_account">
                            <div className="py-2">
                                <p className="mb-3">Crear tu cuenta con la información que nos brindaste en el formulario, si ya tienes cuenta por favor inicia sesión en la parte de arriba.</p>
                                <div className="form-group">
                                <label htmlFor="c_account_password" className="text-black">Contraseña de la cuenta</label>
                                <input type="password" className="form-control" id="c_account_password" name="accountPassword" placeholder="Ingresa una contraseña" onChange={(e) => this.handleFormChange(e)} />
                                </div>
                            </div>
                            </div>
                        </div>
                        )}
                        


                        <div className="form-group">
                            <label htmlFor="c_ship_different_address" className="text-black" data-toggle="collapse" href="#ship_different_address" role="button" aria-expanded="false" aria-controls="ship_different_address">
                                <input type="checkbox" value="1" id="c_ship_different_address" name="differentAddress" disabled={this.state.formDisabled} onChange={(e) => this.handleCheckboxChange(e)} /> ¿Lo enviaremos a un domicilio diferente?</label>
                            <div className="collapse" id="ship_different_address">
                            <div className="py-2">

                                <div className="form-group row">
                                <div className="col-md-6">
                                    <label htmlFor="c_diff_fname" className="text-black">Nombre(s) de la persona <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="c_diff_fname" name="altFirstName" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="c_diff_lname" className="text-black">Apellido(s) de la persona <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="c_diff_lname" name="altLastName" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)} />
                                </div>
                                </div>
                               
                                <div className="form-group row">
                                <div className="col-md-12">
                                    <label htmlFor="c_diff_address" className="text-black">Domicilio <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="c_diff_address" name="altAddress" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)}  placeholder="Calle y número" />
                                </div>
                                </div>

                                <div className="form-group">
                                <input type="text" className="form-control" name="altSecondaryAddress" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)} placeholder="Apartamento, suite, edificio etc. (opcional)" />
                                </div>

                                <div className="form-group row">
                                <div className="col-md-6">
                                    <label htmlFor="c_diff_state_country" className="text-black">Estado <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="c_diff_state_country" disabled={this.state.formDisabled} name="altState" onChange={(e) => this.handleFormChange(e)} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="c_diff_postal_zip" className="text-black">Código Postal <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="c_diff_postal_zip" disabled={this.state.formDisabled} name="altCp" onChange={(e) => this.handleFormChange(e)} />
                                </div>
                                </div>

                                <div className="form-group row mb-5">
                                <div className="col-md-6">
                                    <label htmlFor="c_diff_email_address" className="text-black">Correo electrónico (opcional)</label>
                                    <input type="text" className="form-control" id="c_diff_email_address" disabled={this.state.formDisabled} name="altEmail" onChange={(e) => this.handleFormChange(e)} />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="c_diff_phone" className="text-black">Teléfono <span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" id="c_diff_phone" name="altPhone" disabled={this.state.formDisabled} onChange={(e) => this.handleFormChange(e)}  placeholder="Número telefónico" />
                                </div>
                                </div>

                            </div>

                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="c_order_notes" className="text-black">Notas de la orden</label>
                            <textarea name="orderNotes" id="c_order_notes" cols="30" rows="5" onChange={(e) => this.handleFormChange(e)} className="form-control" 
                            placeholder="Escribe tus notas, comentarios o detalles adicionales..."></textarea>
                        </div>

                        </div>
                    </div>
                    <div className="col-md-6">

                        <div className="row mb-5">
                        <div className="col-md-12">
                            <h2 className="h3 mb-3 text-black">Codigo promocional</h2>
                            <div className="p-3 p-lg-5 border">
                            
                            <label className="text-black mb-3">Ingresa un código promocional si cuentas con uno</label>
                            <div className="input-group w-75">
                                <input type="text" className="form-control" id="c_code" placeholder="Ingresa tu código" aria-label="Coupon Code" aria-describedby="button-addon2" />
                                <div className="input-group-append">
                                <button className="btn btn-primary btn-sm px-4" type="button" id="button-addon2">Aplicar</button>
                                </div>
                            </div>

                            </div>
                        </div>
                        </div>
                        
                        <div className="row mb-5">
                        <div className="col-md-12">
                            <h2 className="h3 mb-3 text-black">Tu pedido</h2>
                            <div className="p-3 p-lg-5 border">
                            <table className="table site-block-order-table mb-5">
                                <thead>
                                    <tr>
                                        <td>Producto</td>
                                        <td>Total</td>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    this.state.cartItems.map(e =>
                                        {
                                            return(
                                            <tr key={e.idCartContent}>
                                                <td>{e.nombre} <strong className="mx-2">x</strong> {e.cantidad}</td>
                                                <td>${this.state.mayoreo == 0 ? e.precio_Venta : Math.ceil(e.precioMayoreo)}</td>
                                            </tr>
                                            )

                                            
                                        })
                                    
                                }
                                {discountTap}
                                <tr>
                                    <td className="text-black font-weight-bold"><strong>Sub Total</strong></td>
                                    <td className="text-black">MXN${this.state.subtotal}</td>
                                </tr>
                                <tr>
                                    <td className="text-black font-weight-bold"><strong>Envío</strong></td>
                                    <td className="text-black">
                                        <select className="form-control" style={{width:"200px"}}
                                        onChange={e => this.updateTotalWithShipment(e)} disabled={this.state.formDisabled}> 
                                            <option value="0">$0.00 - Recoger en tienda (Solo León Guanajuato)</option>
                                            <option value="1">$30.00 - $80.00 Envío a Domicilio (Solo León Guanajuato / Se paga al repartidor el costo de entrega)</option>
                                            <option value="2">$150.00 - Envío Nacional México (FedEx) </option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{textAlign:"center"}}>
                                        <h6>Elige un método de pago</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div className="form-group">
                                            
                                            <input type="radio" id="transferencia" disabled={this.state.formDisabled}  className="form-check-input" value="1" checked={!this.state.payPalComission} onChange={(e) => this.togglePayPalCommission(e)} />
                                            <label htmlFor="transferencia" disabled={this.state.formDisabled} className="form-check-label">Transferencia o depósito</label>
                                            <br></br>
                                            <input type="radio" id="paypal"  className="form-check-input" value="2" checked={this.state.payPalComission} onChange={(e) => this.togglePayPalCommission(e)} />
                                            <label htmlFor="paypal" className="form-check-label">PayPal</label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-black font-weight-bold"><strong>Total de la orden</strong></td>
                                    <td className="text-black font-weight-bold"><strong>MXN${this.state.total}</strong></td>
                                </tr>
                                </tbody>
                            </table>
                            

                            <div className="form-group">
                                <button className="btn btn-primary btn-lg btn-block" onClick={(e) => this.setPaymentMethod(e)}>Colocar pedido</button>
                            </div>
                            
                            <div>
                            {placePayment && !isPayPalMethod && (
                                <div className="border p-3 mb-3">
                                <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsecheque" role="button" aria-expanded="false" aria-controls="collapsecheque">Orden de pago</a></h3>

                                <div className="collapse" id="collapsecheque">
                                <div className="py-2">
                                    <p className="mb-0">Puedes depositar en el establecimiento de tu preferencia o realizar transferencia bancaria (puede demorar en reflejarse tu pago)
                                    , y posteriormente notificarnos vía whatsapp para preparar tu pedido ¡Date prisa! ya que los productos se apartan hasta que confirmas tu pago</p>
                                    <button className="btn btn-primary btn-lg" onClick={(e) => this.placePaymentOrder(e)}>{isLoadingPaymentOrder}</button>
                                </div>
                                </div>
                            </div>
                              )} 
                            {placePayment && isPayPalMethod && (
                            <div className="border p-3 mb-5">
                                <h3 className="h6 mb-0"><a className="d-block" data-toggle="collapse" href="#collapsepaypal" role="button" aria-expanded="false" aria-controls="collapsepaypal">Paypal</a></h3>
                                <div className="collapse" id="collapsepaypal">
                                <div className="py-2">
                                    {payPalBtn}
                                </div>
                                </div>
                            </div>
                            )}
                            </div>
                           
                            </div>
                        </div>
                        </div>

                    </div>
                    </div>
                    
                </div>
                </div>

            </div>
        )
    }

}

export default Checkout;