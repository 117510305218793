import React from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";



 const CLIENT = {
   sandbox:
      "Adx-9uX6dBNW4OQ1c9kCjKHyhXKXaTzPP0TqL53DjpmTDWQGAUdL6CTczHatje_xKEHo4uBSt3lU5h9U",
   production:
      "Adx-9uX6dBNW4OQ1c9kCjKHyhXKXaTzPP0TqL53DjpmTDWQGAUdL6CTczHatje_xKEHo4uBSt3lU5h9U"
 };

 const CLIENT_ID =
   process.env.NODE_ENV === "production" ? CLIENT.production : CLIENT.sandbox;

let PayPalButton = null;
class PaypalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showButtons: false,
      loading: true,
      paid: false,
      amount:0
    };

    window.React = React;
    window.ReactDOM = ReactDOM;
  }

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed } = this.props;
    this.setState({amount:this.props.amount})
    if (isScriptLoaded && isScriptLoadSucceed) {
      PayPalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });
      this.setState({ loading: false, showButtons: true });
    }
  }

  endPaymentWithPayPal = (data) =>
  {
    this.props.finalizePurchasePayPal(data);
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed } = nextProps;

    const scriptJustLoaded =
      !this.state.showButtons && !this.props.isScriptLoaded && isScriptLoaded;

    if (scriptJustLoaded) {
      if (isScriptLoadSucceed) {
        PayPalButton = window.paypal.Buttons.driver("react", {
          React,
          ReactDOM
        });
        this.setState({ loading: false, showButtons: true });
      }
    }
  }
  createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: +"EVE 9 Accessories Order",
          amount: {
            currency_code: "MXN",
            value: parseFloat(this.state.amount)
          }
        }
      ]
    });
  };

  onApprove = (data, actions) => {
    actions.order.capture().then(details => {
      const paymentData = {
        payerID: data.payerID,
        orderID: data.orderID
      };
      console.log("Payment Approved: ", paymentData);
      this.setState({ showButtons: false, paid: true }, ()=>
      {
        this.endPaymentWithPayPal(paymentData);
      });
    });
  };

  render() {
    const { showButtons, loading, paid } = this.state;

    return (
      <div className="main">
        {loading }

        {showButtons && (
          <div>
            <PayPalButton
              createOrder={(data, actions) => this.createOrder(data, actions)}
              onApprove={(data, actions) => this.onApprove(data, actions)}
            />
          </div>
        )}

        {paid && (
          <div className="main">
            <p>¡Tu pago fue autorizado! en breve te estaremos enviando tu pedido, estamos procesando todo</p>
            <FontAwesomeIcon icon={faCircleNotch} spin size="5x" />
          </div>
        )}
      </div>
    );
  }
}


 export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=MXN&locale=es_MX`)(PaypalButton);
