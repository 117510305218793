import React, {Component} from 'react';

class Footer extends Component
{
  state = {}
  render()
  {
    return( 
    <footer className="site-footer custom-border-top">
      <div className="container">
        <div className="row">
        <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <h3 className="footer-heading mb-4">Siguenos en nuestras redes</h3>
            <a href="https://www.facebook.com/Eve9accessories" className="btn eve-fb-btn border-white"><span className="icon-facebook"></span></a>
            <a href="https://www.instagram.com/eve9accessories/?hl=es-la" className="btn eve-btn-instagram border-white"><span className="icon-instagram"></span></a>
          </div>  
        <div className="col-lg-5 ml-auto mb-5 mb-lg-0">
          <div className="row">
            <div className="col-md-12">
              <h3 className="footer-heading mb-4">Links Rápidos</h3>
            </div>
            <div className="col-md-6 col-lg-4">
              <ul className="list-unstyled">
                <li><a href="#">Aviso de privacidad</a></li>
                <li><a href="#">Términos y condiciones</a></li>
                <li><a href="#">Política de devoluciones</a></li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-4">
              <ul className="list-unstyled">
                <li><a href="#">Contáctanos</a></li>
              </ul>
            </div>
          </div>
        </div>
   
        <div className="col-md-6 col-lg-3">
          <div className="block-5 mb-5">
            <h3 className="footer-heading mb-4">EVE 9 Accessories</h3>
            <ul className="list-unstyled">
              <li className="address">Blvd. San Pedro 1618, San Isidro de Jerez, León, Guanajuato, MX C.P. 37530</li>
              <li className="phone"><a href="tel://+5214776465802">+52 1 477 646 58 02 </a></li>
              <li className="email">corporativo@eve9accessories.com.mx</li>
            </ul>
            <h5 className="footer-heading mb-4">Horario de atención a clientes</h5>
            <p>Lunes a viernes de 11:00 a 15:00 y 16:00 a 20:00</p>
            <p>Sábados de 11:00 a 18:00</p>
          </div>
        </div>
      </div>
      <div className="row pt-5 mt-5 text-center">
        <div className="col-md-12">
          <p>
          Copyright &copy; {new Date().getFullYear()} Derechos Reservados | Desarrollado por <a href="http://bluewire.com.mx">Bluewire Solutions Group </a>
          </p>
        </div>
        
      </div>
    </div>
  </footer>);
  }
}

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
