import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Axios from 'axios';
import React from 'react';
import swal from 'sweetalert';
import environment from '../../environment';
import isEmpty from '../../util/isEmpty'
class CarouselItem extends React.Component
{
    state = {
        addingToCart:false
     }

     goToDetails(item)
     {
         var textJson = JSON.stringify(item)
         var a = document.getElementById('productId');
         a.innerHTML = textJson;
         a.click();
     }

    addToCart = (i) =>
    {
        this.setState({addingToCart:true}, ()=>{
            let session = document.cookie.split('; ').find(identifier=> identifier.startsWith('session'));
            if(!isEmpty(session))
            {
                let sessionStr= session.split("=")[1];
                let sessionObj = JSON.parse(sessionStr);
                var req = 
                {
                    idCartContent:0,
                    idCart: sessionObj.cartId,
                    idProducto: i.idProducto,
                    quantity:1,
                    dateAdded: new Date()
                }
                Axios.post(environment.addItem, req)
                .then(result =>
                    {
                        let response = result.data;
                        if(!response.respuesta.success)
                        {
                            swal("Error al añadir elemento", "Ocurrió un error al agregar al carrito, detalles: " + response.respuesta.userMessage, "error");
                            this.setState({addingToCart:false});
                        }
                        else
                        {
                            swal("Éxito", "Se añadió el producto al carrito correctamente", "success");
                            this.setState({addingToCart:false});
                            let refresher = document.getElementById('refreshCart');
                            refresher.click();
                        }
                    })
                .catch(error =>
                    {
                        swal("Error", "Excepción:" + error, "error")
                    })
                }
            });
        }


        
        

    render()
    {
        const loadingIcon =  <FontAwesomeIcon icon={faSpinner} spin />
        let iconSet;
        if(this.state.addingToCart)
        {
            iconSet = loadingIcon;
        }
        else
        {
            iconSet = "";
        }
        return(
            <div className="item-entry mb-4">
            <a href="#topnavbar">
            <img src={this.props.urlImagenes.split("|")[0]} alt="Image" className="img-fluid" onClick={(e) => this.goToDetails(this.props)} />
            </a>
            <h2 className="item-title"><a href="#topnavbar">{this.props.descripcion.substring(0, 30)}</a></h2>
            <strong className="item-price">${this.props.precio_Venta}.00</strong>
            <div className="container">
                <button onClick={() => this.addToCart(this.props)} className="btn btn-outline-primary btn-home" disabled={this.state.addingToCart}>
                   {iconSet}
                    Añadir al carrito</button>
            </div>
            </div>
        );
    }
}
export default CarouselItem;