import React from 'react';
import logo from './logo.svg';
import './App.css';
import SPA from '../src/components/SPA/spa';

function App() {
  
  return (
    <div className="site-wrap">
      <SPA />
    </div>
  );
}

export default App;
