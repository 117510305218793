import React, {Component} from 'react';
import Header from '../header/header';
import Home from '../home/home';
import SiteSection from '../HomeComponents/site-section';
import IconSection from '../HomeComponents/icon-info';
import Footer from '../footer/footer';
import RecentProducts from '../HomeComponents/recent-products';
import Store from '../store/store';
import Store2 from'../store/store2';
import Checkout from '../checkout/checkout';
import PaymentOrder from '../payment-order/payment-order';
import PayPalCheckout from '../paypal-checkout/paypal-checkout';
import ProductPrimaryView from '../product-view/product-view';
import ProductSecondaryView from '../product-view/product-secondary';



class SPA extends Component
{
    constructor(props)
    {
        super(props);
        this.child = React.createRef();
    }
    state = {
        currentInstance:"home",
        category:"",
        paymentOrder:{},
        productToView:null,
        searchTerm:""
    }


    setCurrentInstance = (instance) =>
    {
       this.setState({currentInstance: instance})
    }

    setCategoryFilter = (category) =>
    {
        let instance = "store";
        if(this.state.currentInstance == instance)
        {
            this.setState({category:category}, ()=>
            {
                this.child.current.updateOutsideCategory(category);
            });
        }
        this.setState({category:category}, ()=>
        {
            this.setState({currentInstance: instance})
        })
    }
    switchPaymentOrderInstance = (paymentOrderData) =>
    {
        this.setState({paymentOrder: paymentOrderData}, ()=>
        {
            this.setState({currentInstance:"payment-order"})
        })
    }
    switchPayPalPayment = (payPalData) => 
    {
        this.setState({paymentOrder: payPalData}, ()=>
        {
            this.setState({currentInstance:"paypal-order"})
        })
    }
    setLoginInHeader = () =>
    {
        this.forceUpdate();
    }

    searchQuery = (value) =>
    {
        this.setState({searchTerm:value}, ()=>
        {
            if(this.state.currentInstance != "store")
            {
                this.setState({currentInstance:"store"});
            }
            else
            {
                this.setState({currentInstance:"store2"});
            }
            
        })
    }
    
    setProductUniqueView = (e) =>
    {
        let view = JSON.parse(e);
        this.setState({productToView:view}, ()=>
        {
            if(this.state.currentInstance != "productPrimaryView" && this.state.currentInstance != "productSecondaryView")
            {
                this.setState({currentInstance:"productPrimaryView"});
            }
            else if(this.state.currentInstance === "productPrimaryView")
            {
                this.setState({currentInstance:"productSecondaryView"})
            }
            else if(this.state.currentInstance === "productSecondaryView")
            {
                this.setState({currentInstance:"productPrimaryView"})
            }
        });
    }

    render()
    {
        if(this.state.currentInstance === "home"){
        return(
            <div>
                <Header setCurrentInstance={this.setCurrentInstance} 
                        setCategoryFilter={this.setCategoryFilter}
                        setProductUniqueView={this.setProductUniqueView}
                        searchQuery={this.searchQuery}
                        />
                <Home setCurrentInstance={this.setCurrentInstance} />
                <SiteSection setCategoryFilter={this.setCategoryFilter} />
                <RecentProducts />
                <IconSection />
                <Footer />
            </div>
        )
      }
      else if(this.state.currentInstance === "store")
      {
          return(
              <div>
                 <Header setCurrentInstance={this.setCurrentInstance} 
                        setCategoryFilter={this.setCategoryFilter}
                        setProductUniqueView={this.setProductUniqueView}
                        searchQuery={this.searchQuery}
                        />
                <Store ref={this.child} setCurrentInstance={this.setCurrentInstance} category={this.state.category} searchTerm={this.state.searchTerm}/>
                <Footer />
              </div>
          )
      }
      else if(this.state.currentInstance === "store2")
      {
          return(
              <div>
                 <Header setCurrentInstance={this.setCurrentInstance} 
                        setCategoryFilter={this.setCategoryFilter}
                        setProductUniqueView={this.setProductUniqueView}
                        searchQuery={this.searchQuery}
                        />
                <Store2 ref={this.child} setCurrentInstance={this.setCurrentInstance} category={this.state.category} searchTerm={this.state.searchTerm}/>
                <Footer />
              </div>
          )
      }
      else if(this.state.currentInstance === "checkout")
      {
          return(
            <div>
             <Header setCurrentInstance={this.setCurrentInstance} 
                        setCategoryFilter={this.setCategoryFilter}
                        setProductUniqueView={this.setProductUniqueView}
                        searchQuery={this.searchQuery}
                        />
            <Checkout   setCurrentInstance={this.setCurrentInstance} 
                        switchPaymentOrderInstance={this.switchPaymentOrderInstance}
                        switchPayPalPayment = {this.switchPayPalPayment}
                        setLoginInHeader={this.setLoginInHeader}
                    />
            <Footer />
          </div>
          )
      }
      else if(this.state.currentInstance === "payment-order")
      {
          return(
              <div>
                <Header setCurrentInstance={this.setCurrentInstance} 
                        setCategoryFilter={this.setCategoryFilter}
                        setProductUniqueView={this.setProductUniqueView}
                        searchQuery={this.searchQuery}
                        />
                <PaymentOrder order={this.state.paymentOrder}/>
                <Footer />
              </div>
          )
       
      }
      else if(this.state.currentInstance === "paypal-order")
      {
          return(
              <div>
                 <Header setCurrentInstance={this.setCurrentInstance} 
                        setCategoryFilter={this.setCategoryFilter}
                        setProductUniqueView={this.setProductUniqueView}
                        searchQuery={this.searchQuery}
                        />
                <PayPalCheckout order={this.state.paymentOrder}/>
                <Footer />
              </div>
          )
       
      }
      else if(this.state.currentInstance === "productPrimaryView")
      {
          return(
            <div>
             <Header setCurrentInstance={this.setCurrentInstance} 
                        setCategoryFilter={this.setCategoryFilter}
                        setProductUniqueView={this.setProductUniqueView}
                        searchQuery={this.searchQuery}
                        />
            <ProductPrimaryView 
                product={this.state.productToView}
                setCurrentInstance={this.setCurrentInstance}
                />
            <Footer />
            </div>
          )
      }
      else if(this.state.currentInstance === "productSecondaryView")
      {
          return(
            <div>
            <Header setCurrentInstance={this.setCurrentInstance} 
                        setCategoryFilter={this.setCategoryFilter}
                        setProductUniqueView={this.setProductUniqueView}
                        searchQuery={this.searchQuery}
                        />
            <ProductSecondaryView
                product={this.state.productToView}
                setCurrentInstance={this.setCurrentInstance}
               />
            <Footer />
            </div>
          )
      }
    }
}

export default SPA;