import React, { Component } from "react";
import {faTruckMoving, faCreditCard} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcPaypal } from "@fortawesome/free-brands-svg-icons";

const IconSection = () => 
{
   
        return(
        
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 eve-icon-font-div">
                        <a>
                        <FontAwesomeIcon icon={faTruckMoving} />
                        <p>Envios a todo México</p>
                        </a>
                    </div>
                <div className="col-lg-6 eve-icon-font-div">
                    <a>
                    <FontAwesomeIcon icon={faCreditCard} />
                    <p>Diferentes formas de pago</p>
                    </a>
                </div>
            </div>
        </div>
        );
   
}

export default IconSection;