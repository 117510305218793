import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'
import swal from 'sweetalert';

class PayPalCheckout extends Component{
    state = {
        orderDetails:{}
    }
    componentDidMount()
    {
        let dataOrder = this.props.order;
        var order = 
        {
            checkoutOrder:dataOrder.checkoutOrder,
            confirmationEmail: dataOrder.confirmationEmail,
            contactPhone: dataOrder.contactPhone,
            orderExpirationTime: dataOrder.orderExpirationTime,
            totalSale:dataOrder.totalSale
        }
        this.setState({orderDetails: order}, ()=>{
            swal("Completado", "A continuación encontraras los datos de tu orden", "success")
        });
    }
    render()
    {
        return(
            <div>
               <div className="bg-light py-3 confirmed-order-bar">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 mb-0"><a href="index.html" style={{color:"white"}}>Inicio</a> <span className="mx-2 mb-0">/</span> <strong>Orden Pagada</strong></div>
                    </div>
                </div>
                </div>  

                <div className="site-section">
                <div className="container">
                    <div className="row">
                    <div className="col-md-12 text-center">
                        <span className="icon-check_circle display-3 text-success"></span>
                        <h2 className="display-3 text-black">¡Tu orden fue confirmada y está en camino!</h2>
                        <p>Datos del pedido:</p>
                        <table className="table table-bordered">
                            <thead>
                                <tr><td>Concepto</td><td>Valor</td></tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>N° de Orden</td>
                                    <td>000{this.state.orderDetails.checkoutOrder}</td>
                                </tr>
                                <tr>
                                    <td>Total de Orden</td>
                                    <td>${this.state.orderDetails.totalSale}.00</td>
                                </tr>
                                <tr>
                                    <td>Correo electrónico</td>
                                    <td>{this.state.orderDetails.confirmationEmail}</td>
                                </tr>
                                <tr>
                                    <td>Teléfono de confirmación</td>
                                    <td>{this.state.orderDetails.contactPhone}</td>
                                </tr>
                                <tr>
                                    <td>Orden válida hasta</td>
                                    <td>{this.state.orderDetails.orderExpirationTime}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p className="lead mb-5">Tus productos han sido confirmados y se te estarán enviando a la brevedad</p>
                        <h2>Siguientes pasos</h2>
                        <h5>Sientate y relájate en lo que llega tu pedido, para cualquier duda puedes entrar a tu espacio o contactarnos vía WhatsApp</h5>
                        <button className="btn btn-primary"><FontAwesomeIcon icon={faWhatsapp}></FontAwesomeIcon><a href="https://api.whatsapp.com/send?phone=524776465802&text=tengo%20dudas%20sobre%20mi%20pedido%20" target="_blank" style={{color:"white"}}> ¿Dudas? Escribenos pulsando aquí</a></button>
                    </div>
                </div>
                </div>
                </div>
            </div>
        )
    }
}

export default PayPalCheckout;