import Axios from 'axios'
import React, { Component } from 'react'
import swal from 'sweetalert'
import environment from '../../environment'
import isEmpty from '../../util/isEmpty'

class LoginModal extends Component
{
    state={
        user:"",
        password:"",
        validationMessage:"",
        showingValidator:false
    }

    closeModal(e)
    {
        e.preventDefault()
        this.props.returnToCheckoutInstance()
    }

    login = (e) => 
    {
        e.preventDefault()
        if(!isEmpty(this.state.user) && !isEmpty(this.state.password))
        {
            var req = {
                email: this.state.user,
                password: this.state.password
            }
            Axios.post(environment.login, req)
            .then(response=>
                {
                    let result = response.data;
                    let token = result.datos.token
                    let stringCookie = document.cookie.split('; ').find(identifier=> identifier.startsWith('session')).split("=")[1];
                    let jsonCookie = JSON.parse(stringCookie);
                    let newCookie = 
                    {
                        sessionId:jsonCookie.sessionId,
                        cartId: jsonCookie.cartId,
                        jwt: token
                    }
                    let newJsonStringCookie = JSON.stringify(newCookie);
                    let cookieToAdd = "session=" + newJsonStringCookie + "; expires=" + result.datos.expiration +"; path=/";
                    document.cookie = cookieToAdd;
                    swal("Login exitoso", "Ahora tus datos estarán vinculados", "success").then(()=>
                    {
                        this.props.loggedInSuccessfull(token);
                    })
                })
            .catch(error=>
                {
                  swal("Credenciales inválidas", "Las credenciales otorgadas no son válidas, por favor vuelve a intentar", "error");
                })
        }
        else
        {
           this.setState({validationMessage:"Campos vacíos"}, ()=>
           {
               this.setState({showingValidator:true})
           })
        }
    }

    handleFormChange = (event) =>
    {
        event.persist();
        this.setState({[event.target.name] : event.target.value});
    }

    render()
    {
        const isErrorShowing = this.state.showingValidator   
        return(
        <div>
            <input type="text" className='form-control' placeholder="Ingresa tu correo" name="user" onChange={(e) => this.handleFormChange(e)}  />
            <hr></hr>
            <input type="password" className='form-control' placeholder="Ingresa tu contraseña" name="password" onChange={(e) => this.handleFormChange(e)}  />
            {isErrorShowing && (
                    <div className="row">
                        <div className="col-md-5">
                            <p style={{color:"red"}}>{this.state.validationMessage}</p>
                        </div>
                    </div>
                )
            }
            <hr></hr>
            <button className="btn btn-secondary" onClick={(e) => this.closeModal(e)}>Cerrar</button>
            <button className="btn btn-primary" style={{marginLeft:'5px'}} onClick={(e) => this.login(e)} >Iniciar sesión</button>
        </div>)
    }
}

export default LoginModal